import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import "./Loader.css";

// Component to load and animate the 3D model
function LogoModel() {
  const gltf = useGLTF("/assets/models/logo.glb"); // Path to your logo.glb file
  const modelRef = useRef();

  // Adding rotation animation to the model
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.05; // Spin around Y-axis
    }
  });

  return <primitive ref={modelRef} object={gltf.scene} scale={0.75} />;
}

// Main Loader Component
const Loader = () => {
  return (
    <div className="loader-container">
      <Canvas camera={{ position: [0, 0, 5] }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <LogoModel />
      </Canvas>
 
    </div>
  );
};

export default Loader;
