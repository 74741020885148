import React, { createContext, useState, useContext, useEffect } from "react";

// Create Context
const YearContext = createContext();

// Provider Component
export const YearProvider = ({ children }) => {
  const [yearSelected, setYearSelected] = useState("2024");

  useEffect(() => {
    // Check if there's a stored year in localStorage
    const storedYear = localStorage.getItem("yearSelected");

    if (!storedYear) {
      // If no year in localStorage, set the default year to '2024'
      localStorage.setItem("yearSelected", "2024");
      setYearSelected("2024"); // Default year
    } else {
      // If year is in localStorage, set it
      setYearSelected(storedYear);
    }
  }, []);

  const onYearChange = (newYear) => {
    setYearSelected(newYear);
    localStorage.setItem("yearSelected", newYear);
  };

  // Function to clear year from localStorage (can be called on logout)
  const clearYearFromLocalStorage = () => {
    localStorage.removeItem("yearSelected");
    setYearSelected("2024"); // Reset to default 2024 on logout
  };

  return (
    <YearContext.Provider
      value={{ yearSelected, onYearChange, clearYearFromLocalStorage }}
    >
      {children}
    </YearContext.Provider>
  );
};

// Custom Hook to use Year Context
export const useYearContext = () => {
  return useContext(YearContext);
};
