
import './App.css';
import React ,{useEffect,lazy,Suspense} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Loader from './components/Loader';

import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { YearProvider } from './admin/components/YearContext';




// Components
const Loginnn = lazy(() => import('./components/Loginnn'));

const Forgetpassword = lazy(() => import('./components/forgetpassword'));
const Homepage = lazy(() => import('./landing/pages/Homepage'));
const Nav = lazy(() => import('./landing/components/Nav'));
const Chatbot = lazy(() => import('./components/Chatbot'));
const WhatsAppChatButton = lazy(() => import('./components/Whatsapp'));
 
// Landing Pages
const Aboutus = lazy(() => import('./landing/pages/Aboutus'));
const Refundstatus = lazy(() => import('./landing/pages/Refundstatus'));
const Price = lazy(() => import('./landing/pages/price'));
const Contactusform = lazy(() => import('./landing/pages/contactusform'));
 
const Federal = lazy(() => import('./landing/pages/federal'));
const Amendment = lazy(() => import('./landing/pages/amendment'));
const Itinpreparation = lazy(() => import('./landing/pages/itinpreparation'));
const Fbar = lazy(() => import('./landing/pages/fbar'));
const Bookkeeping = lazy(() => import('./landing/pages/Bookkeeping'));
const W4assistance = lazy(() => import('./landing/pages/w4assistance'));
const Taxextension = lazy(() => import('./landing/pages/taxextension'));
const Estimatetax = lazy(() => import('./landing/pages/estimatetax'));
const Taxplanning = lazy(() => import('./landing/pages/taxplanning'));
const Filingoptions = lazy(() => import('./landing/pages/filingoptions'));
const Representation = lazy(() => import('./landing/pages/representation'));
const International = lazy(() => import('./landing/pages/international'));
const Boir = lazy(() => import('./landing/pages/boir'));
const Filing = lazy(() => import('./landing/pages/filing'));
 
const Business = lazy(() => import('./landing/pages/business'));
const Businessreturns = lazy(() => import('./landing/pages/businessreturns'));
const Accounting = lazy(() => import('./landing/pages/accounting'));
const Payroll = lazy(() => import('./landing/pages/payroll'));
const Careers = lazy(() => import('./landing/pages/careers'));
const Terms = lazy(() => import('./landing/pages/terms'));
const Referandearnn = lazy(() => import('./landing/pages/referandearnn'));
const Privacypolicy = lazy(() => import('./landing/pages/privacypolicy'));
const Refundpolicy = lazy(() => import('./landing/pages/refundpolicy'));
const Staterefundstatus = lazy(() => import('./landing/pages/staterefundstatus'));

// Authentication
const Signup = lazy(() => import('./landing/components/signup'));
const Loginn = lazy(() => import('./landing/components/loginn'));

 
// Miscellaneous
const Cosection = lazy(() => import('./landing/components/Cosections'));

const Testimonial = lazy(() => import('./landing/pages/Testimonials'));
const Planningservices = lazy(() => import('./landing/pages/Planningservices'));
 
const Se = lazy(() => import('./landing/pages/Se'));
const Se1 = lazy(() => import('./landing/pages/Se1'));

//Admin Pages
const Admindashboard = lazy(() => import('./admin/pages/Admindashboard')) ;
const Adminanalytics = lazy(() => import('./admin/pages/Adminanalytics')) ;
const Adminclients = lazy(() => import('./admin/pages/Adminclients')) ;
const Adminleads = lazy(() => import('./admin/pages/Adminleads'));
const Employeestatus = lazy(() => import('./admin/pages/Employeestatus'));
const Admindocuments = lazy(() => import('./admin/pages/Admindocuments'));
const Adminemployees = lazy(() => import('./admin/pages/Employeeinfo'));
const Searchclient = lazy(() => import('./admin/pages/SearchClient'));
const Admininvoice = lazy(() => import('./admin/pages/Admininvoice'));
const Adminpayments = lazy(() => import('./admin/pages/Adminpayments'));
const Adminprofile = lazy(() => import('./admin/pages/Adminprofile'));
const Adminsettings = lazy(() => import('./admin/pages/Adminsettings'));
const Clientviewdocs = lazy(() => import('./admin/pages/Clientviewdocs'));
const EditEmployee = lazy(() =>  import ('./admin/pages/EditEmployee'));
//employee pages
const Empdashboard = lazy(() => import('./employee/pages/Empdashboard'));
const Empshifts = lazy(() => import('./employee/pages/Empshifts'));
const Empprofile = lazy(() => import('./employee/pages/Empprofile'));
const Empleads = lazy(() => import('./employee/pages/Empleads'));
const Empclients = lazy(() => import('./employee/pages/Empclients'));
const Empdocuments = lazy(() => import('./employee/pages/Empdocuments'));
const Empinvoice = lazy(() => import('./employee/pages/Empinvoice'));
const Empsettings = lazy(() => import('./employee/pages/Empsettings'));
const Empclientviewdocs = lazy(() => import('./employee/pages/Empclientviewdocs'));
const Empsearchclient = lazy(() => import('./employee/pages/Empsearchclient'));
const Emppayments = lazy(() => import('./employee/pages/Emppayments'));

//client pages
const Clientdashboard = lazy(() => import('./client/pages/Clientdashboard'));
const Clienttaxorganizer = lazy(() => import('./client/pages/Taxorganizer'));
const Clientdocuments = lazy(() => import('./client/pages/Clientdocuments'));
const Clientreferandearn = lazy(() => import('./client/pages/Referandearn'));
const Clientinvoices = lazy(() => import('./client/pages/Clientinvoices'));
const Clientprofile = lazy(() => import('./client/pages/Clientprofile'));
const Clientsettings = lazy(() => import('./client/pages/Clientsettings'));
const Clienthelpcenter = lazy(() => import('./client/pages/Helpcenter'));



function App() {

//  // useEffect for disabling right-click and Developer Tools shortcuts
//  useEffect(() => {
//   const disableRightClick = (e) => {
//     e.preventDefault();
//     toast.warn("Right-click is disabled for security reasons.");
//   };

//   const disableDevToolsShortcuts = (e) => {
//     if (
//       (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C')) || 
//       e.key === 'F12'
//     ) {
//       e.preventDefault();
//       toast.warn("Developer tools shortcuts are disabled.");
//     }
//   };

//   // Attach event listeners
//   document.addEventListener('contextmenu', disableRightClick);
//   document.addEventListener('keydown', disableDevToolsShortcuts);

//   // Cleanup listeners on unmount
//   return () => {
//     document.removeEventListener('contextmenu', disableRightClick);
//     document.removeEventListener('keydown', disableDevToolsShortcuts);
//   };
// }, []);



  return (
    <YearProvider>
    <BrowserRouter>
    <ToastContainer position='top-right'/>
    
    <Suspense fallback={<Loader />}>

      
        <Routes>
          {/* Public Pages */}
          <Route path="/" element={<Homepage />} />
          <Route path="/nav" element={<Nav />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/refundstatus" element={<Refundstatus />} />
          <Route path="/price" element={<Price />} />
          
          <Route path="/federal" element={<Federal />} />
          <Route path="/amendment" element={<Amendment />} />
          <Route path="/itinpreparation" element={<Itinpreparation />} />
          <Route path="/fbar" element={<Fbar />} />
          <Route path="/w4assistance" element={<W4assistance />} />
          <Route path="/taxextension" element={<Taxextension />} />
          <Route path="/estimatetax" element={<Estimatetax />} />
          <Route path="/taxplanning" element={<Taxplanning />} />
          <Route path="/filingoptions" element={<Filingoptions />} />
          <Route path="/representation" element={<Representation />} />
          <Route path="/international" element={<International />} />
          <Route path="/boir" element={<Boir />} />
          <Route path="/filing" element={<Filing />} />


          <Route path="/se" element={<Se />} />
          <Route path="/bookkeeping" element={<Bookkeeping />} />

          <Route path="/business" element={<Business />} />
          
      
          <Route path="/se1" element={<Se1 />} />
          <Route path="/cosection" element={<Cosection />} />
          <Route path="/testimonials" element={<Testimonial />} />
          <Route path="/planningservices" element={<Planningservices />} />
          <Route path="/businessreturns" element={<Businessreturns />} />
          <Route path="/accounting" element={<Accounting />} />
          <Route path="/staterefundstatus" element={<Staterefundstatus />} />
          <Route path="/payroll" element={<Payroll />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/referandearnn" element={<Referandearnn />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/refundpolicy" element={<Refundpolicy />} />
          <Route path="/contactusform" element={<Contactusform />} />

          {/* Authentication */}
          <Route path="/login" element={<Loginn />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/Loginnn" element={<Loginnn />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />

          {/* Admin pages */}
          
          <Route path="/admin" element={<Admindashboard />} />
          <Route path="/adminanalytics" element={<Adminanalytics />} />
          <Route path="/clients" element={<Adminclients />} />
          <Route path="/leads" element={<Adminleads />} />
          <Route path="/employeestatus" element={<Employeestatus />} />
          <Route path="/documentstimeline" element={<Admindocuments />} />
          <Route path="/employeinformation" element={<Adminemployees />} />
          <Route path="/searchclient" element={<Searchclient />} />
          <Route path="/invoice" element={<Admininvoice />} />
          <Route path="/payments" element={<Adminpayments />} />
          <Route path="/adminprofile" element={<Adminprofile />} />
          <Route path="/adminsettings" element={<Adminsettings />} />
          <Route path="/viewclientdocs" element={<Clientviewdocs />} />
          <Route path="/edit-employee/:empId" element={<EditEmployee />} />


          {/* Employee pages */}
          <Route path="/employee" element={<Empdashboard />} />
          <Route path="/empshifts" element={<Empshifts />} />
          <Route path="/empProfile" element={<Empprofile />} />
          <Route path="/empLeads" element={<Empleads />} />
          <Route path="/empClients" element={<Empclients />} />
          <Route path="/empdocuments" element={<Empdocuments />} />
          <Route path="/empinvoices" element={<Empinvoice />} />
          <Route path="/empsettings" element={<Empsettings />} />
          <Route path="/empclientviewdocs" element={<Empclientviewdocs />} />
          <Route path="/empsearchclient" element={<Empsearchclient />} />
          <Route path="/emppayments" element={<Emppayments />} />

          {/* Client pages */}
          <Route path="/client" element={<Clientdashboard />} />
          <Route path="/taxorganizer" element={<Clienttaxorganizer />} />
          <Route path="/documents" element={<Clientdocuments />} />
          <Route path="/referandearn" element={<Clientreferandearn />} />
          <Route path="/clientinvoices" element={<Clientinvoices />} />
          <Route path="/clientprofile" element={<Clientprofile />} />
          <Route path="/settings" element={<Clientsettings />} />
          <Route path="/helpcenter" element={<Clienthelpcenter />} />
        
          </Routes>
          </Suspense>
       

    </BrowserRouter>
    </YearProvider>
  );
}

export default App;
